export default {
    namespaced:true,
    state:{
        shopcart:[]
    },
    getters:{
        getShopCart(state){
            return state.shopcart;
        }
    },
    mutations:{
        setShopCart(state,shop){
            state.shopcart = shop;
        }
    },
    actions:{}
}