<template>
    <div class="box">
        <van-nav-bar title="接车环检单" class="navBar" left-arrow @click-left="back()"></van-nav-bar>
        <div class="container">
            <!-- <van-cell-group inset class="importCar">
                <van-button type="success" size="small">导入店内车辆</van-button>
                <van-icon name="arrow" color="#8195A5"/>
            </van-cell-group> -->
            <van-form ref="theForm">
                <div class="formContent">
                    <h3>基础信息</h3>
                    <van-cell-group inset class="baseMsg">
                        <van-field
                            v-model="state.c_name"
                            name="姓名"
                            label="姓名:"
                            placeholder="请输入客户姓名"
                            :rules="[{ required: true, message: '请填写姓名' }]"
                            :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]"
                        />
                        <span style="color:red">*</span>
                        <van-field
                            v-model="state.c_conector"
                            name="联系人"
                            label="联系人:"
                            placeholder="请填写联系人"
                            :rules="[{ required: true, message: '请填写联系人' }]"
                            :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]"
                        />
                        <span style="color:red">*</span>
                        <van-field
                            v-model="state.c_phone"
                            type="phone"
                            name="手机号"
                            label="手机号:"
                            placeholder="手机号"
                            :rules="[{ required: true, message: '请输入正确的手机号',pattern:/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/}]"
                            :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]"
                        />
                        <span style="color:red">*</span>
                        <van-field
                            v-model="state.c_customer_id_num"
                            name="身份证号"
                            label="身份证号:"
                            placeholder="身份证号"
                            :rules="[{ required: true, message: '请输入正确的身份证号',pattern:/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/}]"
                            :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]"
                        />
                        <span style="color:red">*</span>
                        <van-field
                            v-model="state.c_car_plate_number"
                            name="车牌号"
                            label="车牌号:"
                            placeholder="车牌号"
                            :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]"
                            :rules="[{ required: true, message: '请输入正确的车牌号',pattern:/^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[a-zA-Z](([DF]((?![IO])[a-zA-Z0-9](?![IO]))[0-9]{4})|([0-9]{5}[DF]))|[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1})$/}]"
                        >
                            <!-- <template #button>
                                <van-button size="mini" type="warning" class="search" @click="queryCarInfo()">查询车辆详情</van-button>
                            </template> -->
                        </van-field>
                        <span style="color:red;">*</span>
                        <van-field
                            v-model="state.c_customer_address"
                            name="住址"
                            label="住址:"
                            placeholder="请输入住址"
                            :rules="[{ required: true, message: '请输入住址'}]"
                            :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]"
                        />
                        <span style="color:red">*</span>
                        <van-field
                            v-model="state.c_customer_type"
                            readonly
                            clickable
                            name="calendar"
                            label="类型:"
                            placeholder="点击选择类型"
                            @click="state.showTypeChoose = true"
                            :rules="[{ required: true, message: '请选择类型'}]"
                            :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]"
                        />
                        <span style="color:red">*</span>
                        <van-field
                            v-model="state.vin"
                            name="车架号"
                            label="车架号:"
                            placeholder="请输入车架号"
                            :rules="[{ required: true, message: '请输入正确的车架号',pattern:/^[A-HJ-NPR-Z\d]{17}$/}]"
                            :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]"
                        />
                        <span style="color:red">*</span>
                        <van-field
                            v-model="state.engine_no"
                            name="发动机号"
                            label="发动机号:"
                            placeholder="请输入发动机号"
                            :rules="[{ required: true, message: '请输入发动机号'}]"
                            :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]"
                        />
                        <span style="color:red">*</span>
                        <van-field
                            v-model="state.c_car_km"
                            name="里程"
                            label="里程:"
                            placeholder="里程数（km）"
                            :rules="[{ required: true, message: '请填写里程'}]"
                            :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]"
                        />
                        <span style="color:red">*</span>
                        <van-field
                            v-model="state.c_car_series"
                            name="车系"
                            label="车系:"
                            placeholder="请填写车系"
                            :rules="[{ required: true, message: '请填写车系'}]"
                            :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]"
                        />
                        <span style="color:red">*</span>
                        <van-field
                            v-model="state.c_car_name"
                            name="车型"
                            label="车型:"
                            placeholder="填写车型"
                            :rules="[{ required: true, message: '请填写车型'}]"
                            :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]"
                        />
                        <span style="color:red">*</span>
                    </van-cell-group>
                    <h3>额外信息</h3>
                    <van-cell-group inset class="baseMsg">
                        <van-field
                            v-model="state.c_car_color"
                            readonly
                            clickable
                            label="颜色:"
                            placeholder="点击选择颜色"
                            @click="state.showColorChoose = true"
                            :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]"
                        />
                        <van-field
                            v-model="state.c_customer_sex"
                            readonly
                            clickable
                            label="客户性别:"
                            placeholder="点击选择性别"
                            @click="state.showSexChoose = true"
                            :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]"
                        />
                        <van-field
                            v-model="state.c_introducer"
                            name="介绍人"
                            label="介绍人:"
                            placeholder="请输入介绍人姓名"
                            :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]"
                        />
                    </van-cell-group>
                        
                    <h3>保养阶段</h3>
                    <van-checkbox-group v-model="state.c_maintain_stage_arr" style="margin-left:.5rem">
                        <van-checkbox checked-color="#5E2F88" :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]" v-for="item in baseInfo.b_maintain_stages" :key="item" :name="item" style="margin-top:1rem">{{item}}</van-checkbox>
                    </van-checkbox-group>
                    <h3>客户陈述/原因分析/预诊断结果</h3>
                    <van-field
                        v-model="state.c_pre_diagnosis"
                        type="textarea"
                        name="备注"
                        rows="2"
                        label=""
                        placeholder="请填写"
                        style="border:1px dashed black;margin-left:.5rem;width:calc(100% - 1rem)"
                        :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]"
                    />
                    <h3>问诊类型</h3>
                    <van-checkbox-group v-model="state.c_repair_type" style="display:flex;flex-direction:row;margin-left:.5rem">
                        <van-checkbox checked-color="#5E2F88" :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]" name="机电">机电</van-checkbox>
                        <van-checkbox checked-color="#5E2F88" :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]" name="钣金" style="margin-left:1rem">钣金</van-checkbox>
                        <van-checkbox checked-color="#5E2F88" :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]" name="喷漆" style="margin-left:1rem">喷漆</van-checkbox>
                        <van-checkbox checked-color="#5E2F88" :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]" name="选装/其他" style="margin-left:1rem">选装/其他</van-checkbox>
                    </van-checkbox-group>
                    <h3>确认项目</h3>
                    <van-checkbox-group v-model="state.c_confirm_matters" style="margin-left:.5rem">
                        <van-checkbox checked-color="#5E2F88" :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]" :name="baseInfo.b_confirm_matters[0]" style="margin-top:1rem">{{baseInfo.b_confirm_matters[0]}}</van-checkbox>
                        <van-checkbox checked-color="#5E2F88" :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]" :name="baseInfo.b_confirm_matters[1]" style="margin-top:1rem">{{baseInfo.b_confirm_matters[1]}}</van-checkbox>
                        <van-checkbox checked-color="#5E2F88" :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]" :name="baseInfo.b_confirm_matters[2]" style="margin-top:1rem">{{baseInfo.b_confirm_matters[2]}}</van-checkbox>
                    </van-checkbox-group>
                    <h3>费用估计</h3>
                    <van-field
                        v-model="state.c_pre_parts_pay"
                        type="number"
                        name="预估零件费"
                        label="预估零件费："
                        placeholder="￥"
                        :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]"
                    />
                    <van-field
                        v-model="state.c_pre_hour_pay"
                        type="number"
                        name="预估工时费"
                        label="预估工时费："
                        placeholder="￥"
                        :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]"
                    />
                    <van-field
                        v-model="state.c_pre_total_pay"
                        type="number"
                        name="总费用"
                        label="总费用："
                        placeholder="￥"
                        disabled
                    />
                    <div style="display:flex;flex-direction:row">
                        <h3>预计交车时间</h3>
                        <h3 style="color:red">*</h3>
                    </div>
                    <div @click="showDatetimePicker" style="text-align:left;margin-left:1rem;font-size:1.25rem;color:rgba(0,0,0,0.5)">{{state.choose_deliver_time}}</div>
                    <h3>客户其他要求</h3>
                    <van-field
                        v-model="state.c_other_require"
                        type="textarea"
                        name="客户其他要求"
                        rows="2"
                        label=""
                        placeholder="请填写"
                        style="border:1px dashed black;margin-left:.5rem;width:calc(100% - 1rem)"
                        :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]"
                    />
                    <h3>外观检查</h3>
                    <van-field
                        v-model="state.c_out_check"
                        type="textarea"
                        name="外观检查"
                        rows="2"
                        label=""
                        placeholder="请填写"
                        style="border:1px dashed black;margin-left:.5rem;width:calc(100% - 1rem)"
                        :disabled = "state.footerBtnMsg != state.footerBtnMsgs[0]"
                    />
                    <h3>车辆拍照（最多20张）</h3>
                    <div style="display:flex;flex-direction:row;flex-wrap: wrap;">
                        <div v-for="item in state.c_pics" :key="item" style="width:29.5%;min-width:29.5%;margin-left:3%;margin-top:1rem;height:5rem;display:flex">
                            <van-image style="width:100%;height:100%" :src="item" @click="previewPhotos(item)"></van-image>
                            <div v-for="item in state.c_pics" :key="item" @click="deletePhoto(item)" style="position:absolute;width:1.5rem;height:1.5rem;border:2px solid #7259A2;background-color:#7259A2;color:white;line-height:1.5rem;border-radius:0.75em;margin-top:-0.75rem;margin-left:-0.25rem">X</div>
                        </div>
                        <!-- <div v-if="state.c_pics.length<5" @click="openScan" style="width:29.5%;min-width:29.5%;margin-left:3%;margin-top:.9rem;border:2px solid #7259A2;font-size:3rem;line-height:5rem;border-radius:1rem;color:#7259A2;background-color:#e8e8e8">+ -->
                        <van-uploader
                        v-if="state.c_pics.length<20 && state.footerBtnMsg == state.footerBtnMsgs[0]"
                        :after-read="afterRead"
                        :show-upload="true"
                        ref="uploader"
                        style="width:29.5%;min-width:29.5%;margin-left:3%;margin-top:.9rem;border:2px solid #7259A2;font-size:3rem;line-height:5rem;border-radius:1rem;color:#7259A2;background-color:#e8e8e8"
                        >
                            <van-icon name="plus" style="margin-left:1.75rem" />
                        </van-uploader>
                        <!-- </div> -->
                    </div>
                    <h3 style="margin-top:2rem">客户签名留存</h3>
                    <div style="display:flex;flex-direction:row;flex-wrap: wrap;">
                        <div v-for="item in state.c_sign_pics" :key="item" style="width:29.5%;min-width:29.5%;margin-left:3%;margin-top:1rem;height:5rem;display:flex">
                            <van-image style="width:100%;height:100%" :src="item" @click="previewPhotos_s(item)"></van-image>
                            <div v-for="item in state.c_sign_pics" :key="item" @click="deletePhoto_s(item)" style="position:absolute;width:1.5rem;height:1.5rem;border:2px solid #7259A2;background-color:#7259A2;color:white;line-height:1.5rem;border-radius:0.75em;margin-top:-0.75rem;margin-left:-0.25rem">X</div>
                        </div>
                        <van-uploader
                        v-if="state.c_sign_pics.length<1 && state.footerBtnMsg == state.footerBtnMsgs[0]"
                        :after-read="afterRead_s"
                        :show-upload="true"
                        ref="uploader"
                        style="width:29.5%;min-width:29.5%;margin-left:3%;margin-top:.9rem;border:2px solid #7259A2;font-size:3rem;line-height:5rem;border-radius:1rem;color:#7259A2;background-color:#e8e8e8"
                        >
                            <van-icon name="plus" style="margin-left:1.75rem" />
                        </van-uploader>
                    </div>
                    <div style="width:100vw;height:6.5rem;background:#F1F5F8"></div>
                </div>
                <footer>
                    <van-button @click="alertSubmit" color="rgba(94, 47, 136, 1)" :disabled="state.c_name.length <= 0 || 
                    state.c_phone.length != 11 || 
                    state.c_conector.length <= 0 ||
                    state.c_customer_id_num <= 0 ||
                    state.c_car_plate_number.length <= 0 ||
                    state.c_customer_address.length <= 0 ||
                    state.c_customer_type.length <= 0 ||
                    state.vin.length <= 0||
                    state.engine_no.length <= 0 ||
                    state.c_car_km.length <= 0 ||
                    state.c_car_series.length <= 0 ||
                    state.c_car_name.length <= 0 || 
                    state.choose_deliver_time == '请选择 >'">{{state.footerBtnMsg}}</van-button>
                    <div class="bar"></div>
                </footer>
            </van-form>
        </div>
    </div>
    <van-popup v-model:show="state.showTypeChoose" position="bottom"> 
        <van-picker show-toolbar title="类型" :columns="baseInfo.b_customer_type" @confirm="onChooseTypeConfirm" @cancel="state.showTypeChoose=false"></van-picker>
    </van-popup>
    <van-popup v-model:show="state.showColorChoose" position="bottom"> 
        <van-picker show-toolbar title="车辆颜色" :columns="baseInfo.b_car_colors" @confirm="onChooseColorConfirm" @cancel="state.showColorChoose=false"></van-picker>
    </van-popup>
    <van-popup v-model:show="state.showSexChoose" position="bottom"> 
        <van-picker show-toolbar title="客户性别" :columns="baseInfo.b_customer_sex" @confirm="onChooseCustomerSexConfirm" @cancel="state.showSexChoose=false"></van-picker>
    </van-popup>
    <van-dialog v-model:show="state.showConfirmToSubmit" :title="state.footerBtnAlertMsgs[0]" show-cancel-button messageAlign="left" confirmButtonText="确认" @confirm="submit()">   
    </van-dialog>
    <van-dialog v-model:show="state.showConfirmToPushNoti" :title="state.footerBtnAlertMsgs[1]" show-cancel-button messageAlign="left" confirmButtonText="确认" @confirm="submit()">   
    </van-dialog>
    <van-dialog v-model:show="state.showConfirmToQueRen" :title="state.footerBtnAlertMsgs[2]" show-cancel-button messageAlign="left" confirmButtonText="确认" @confirm="submit()">   
    </van-dialog>
    <van-popup v-model:show="state.showPictureOrigin" position="bottom"> 
        <van-button>从相册选择</van-button>
        <van-picker show-toolbar title="客户性别" :columns="baseInfo.b_customer_sex" @confirm="onChooseCustomerSexConfirm" @cancel="state.showSexChoose=false"></van-picker>
    </van-popup>

    <van-popup v-model:show="state.showDateTimePicker" round position="bottom">
        <van-datetime-picker
        v-model="state.currentDate"
        type="datetime"
        :min-date="state.minDate"
        :columns-order="['year', 'month', 'day','hour','minute']"
        :formatter="datetimePicker_formatter"
        @cancel="datetimePicker_onTimeOut"
        @confirm="datetimePicker_onTimeIn"
        @changeFn="datetimePicker_onShift"
        />
    </van-popup>
 
    
</template>
<script setup>
    import {computed, reactive,ref} from 'vue'
    import { useStore } from 'vuex'
    import { useRouter } from "vue-router";
    import {updateInterrogationListStatus,pushNotificationToUserWX,saveInsepctionBill} from "api/insepction.js"

    const theForm = ref(null)
    const uploader = ref(null);
    const router = useRouter();
    const store = useStore()

    const baseInfo = {
        b_maintain_stages : ['首二保','每1万公里保养','每2万公里保养','每4万公里保养'],
        b_confirm_matters : ['确认提醒客户保管好自己的贵重物品','确认客户是否在店等待','确认是否外观脏暂不做外观检查'],
        b_customer_type : ['个人','团体'],
        b_car_colors: ["黑色","白色","银灰色","深灰色","红色","橙色","绿色","蓝色","咖啡色","紫色","香槟色","多彩色","黄色","其他"],
        b_customer_sex :['男','女']
    }
    const state = reactive({
        //照片url数组
        c_pics:[],
        //客户签名照url数组（其实最多就一个）
        c_sign_pics:[],
        showTypeChoose:false,
        showColorChoose:false,
        showSexChoose:false,
        showPictureOrigin:false,
        //交车时间
        showDateTimePicker: false,
        currentDate: new Date(),
        minDate: new Date(),
        choose_deliver_time: '请选择 >',
        //基础信息
        c_name:'',
        c_conector:'',
        c_introducer:'',
        c_customer_id_num:'',
        c_customer_sex:'',
        engine_no:'',
        vin:'',
        c_car_series:'',
        c_customer_type:'',
        c_customer_address:'',
        c_phone:'',
        c_car_plate_number:'',
        c_car_km:'',
        c_car_name:'',
        c_car_color:'',
        //保养阶段
        c_maintain_stage_arr : [],
        //客户陈述/原因分析/预诊断结果
        c_pre_diagnosis:'',
        //问诊类型（机电、钣金、喷漆、选装/其他）
        c_repair_type:[],
        //确认事项
        c_confirm_matters:[],
        //预估零件费
        c_pre_parts_pay:0,
        //预估工时费
        c_pre_hour_pay:0,
        //预估总费用
        c_pre_total_pay: computed( ()=> parseFloat(state.c_pre_parts_pay) + parseFloat(state.c_pre_hour_pay)),
        //客户其他要求
        c_other_require:'',
        //外观检查
        c_out_check: '',

        //
        list_num : '',

        //
        showConfirmToSubmit: false,
        showConfirmToPushNoti : false,
        showConfirmToQueRen : false,

        //
        footerBtnMsgs : ['创 建','推送给客户','手动确认','完成'],
        footerBtnAlertMsgs : ['确认创建环检单？','推送给客户？（请确保客户已关联小程序并授权了订阅消息）','确保客户已确认上述单据内容？'],
        footerBtnMsg : '创 建',

    })



    import logo from 'assets/logo.png';
import { ImagePreview } from 'vant';
import { BrowserMultiFormatReader } from "@zxing/library";
import axios from 'axios';
let codeReader = reactive(new BrowserMultiFormatReader());
    const alertSubmit = async () => {

        if(state.footerBtnMsg == state.footerBtnMsgs[0]){
            state.showConfirmToSubmit = true;
        }else if(state.footerBtnMsg == state.footerBtnMsgs[1]){
            state.showConfirmToPushNoti = true;
        }else if(state.footerBtnMsg == state.footerBtnMsgs[2]){
            state.showConfirmToQueRen = true;
        }else if(state.footerBtnMsg == state.footerBtnMsgs[3]){
            back();
        }

    }

    //类型选择器确认
    const onChooseTypeConfirm = (typeStr) => {
        state.c_customer_type = typeStr
        state.showTypeChoose = false;
    }

    //颜色选择器确认
    const onChooseColorConfirm = (colorStr) => {
        state.c_car_color = colorStr
        state.showColorChoose = false;
    }

    //性别选择器确认
    const onChooseCustomerSexConfirm = (sexStr) => {
        state.c_customer_sex = sexStr
        state.showSexChoose = false
    }

    //保养阶段复选框点击
    const mainTainStageChecked = () => {
        
    }

    const afterRead = (file) => {

        console.log("扫描结果准备处理");
        // Toast.success("扫描结果准备处理")
 
        const param = new FormData();
        param.append("file", file.file);
        
        const config = {
            headers: { "Content-Type": "application/json;charset=UTF-8" }
        };
        axios.post("/auth/store/upload", param, config).then(res => {
            if(res.data.code == "0"){
                state.c_pics.push(res.data.data.src)
            }
        });

    };

    const afterRead_s = (file) => {
        console.log("扫描结果准备处理");
        // Toast.success("扫描结果准备处理")
 
        const param = new FormData();
        param.append("file", file.file);
        
        const config = {
            headers: { "Content-Type": "application/json;charset=UTF-8" }
        };
        axios.post("/auth/store/upload", param, config).then(res => {
            if(res.data.code == "0"){
                state.c_sign_pics.push(res.data.data.src)
            }
        });
    };


    function getObjectURL(file) {
        let url = null;
        if (window.createObjectURL !== undefined) {
            // basic
            url = window.createObjectURL(file);
        } else if (window.URL !== undefined) {
            // mozilla(firefox)
            url = window.URL.createObjectURL(file);
        } else if (window.webkitURL !== undefined) {
            // webkit or chrome
            url = window.webkitURL.createObjectURL(file);
        }
        return url;
    }
    
    const submit = async () => {
        if(state.footerBtnMsg == state.footerBtnMsgs[0]){
            var userInfo = store.getters["user/users"];
            //确认项目数据
            let isSaveValue = 0;
            let isCheckValue = 0;
            let isWaitValue = 0;
            for(let i =0; i <state.c_confirm_matters.length; i ++){
                let item = state.c_confirm_matters[i];
                if(item == baseInfo.b_confirm_matters[0]){
                    isSaveValue = 1;
                }
                if(item == baseInfo.b_confirm_matters[1]){
                    isWaitValue = 1;
                }
                if(item == baseInfo.b_confirm_matters[2]){
                    isCheckValue = 1;
                }
            }
            //保养阶段数据
            let maintainStageValue = -1;
            for(let i = 0; i < state.c_maintain_stage_arr.length; i ++){
                let item = state.c_maintain_stage_arr[i];
                if(item == baseInfo.b_maintain_stages[0]){
                    maintainStageValue = 0;
                }
                if(item == baseInfo.b_maintain_stages[1]){
                    maintainStageValue = 1;
                }
                if(item == baseInfo.b_maintain_stages[2]){
                    maintainStageValue = 2;
                }
                if(item == baseInfo.b_maintain_stages[3]){
                    maintainStageValue = 4;
                }
            }
            //拼装formData
            var form = Object.create(null);
            form.customerName=state.c_name;

            form.conectorName = state.c_conector;
            form.customerIdNum = state.c_customer_id_num;
            form.customerAddress = state.c_customer_address;
            form.vin = state.vin;
            form.engineNo = state.engine_no;
            form.carSeries = state.c_car_series;
            form.customerSex = state.c_customer_sex;
            form.customerType = state.c_customer_type;
            form.vin = state.vin;
            form.engineNo = state.engine_no;
            form.carSeries = state.c_car_series;
            form.color = state.c_car_color;
            form.customerSex = state.c_customer_sex;
            form.customerIntroducer = state.c_introducer;

            form.phone=state.c_phone;
            form.carNum=state.c_car_plate_number;
            form.km = state.c_car_km;
            form.caType = state.c_car_name;
            form.carOil = 0;
            form.maintenanceStage = maintainStageValue;
            form.customerStatement = state.c_pre_diagnosis;
            form.serviceType = state.c_repair_type.toString();
            form.staffName = userInfo.name;
            form.staffNo = userInfo.staffNo;
            form.hourCost = state.c_pre_hour_pay;
            form.partCost = state.c_pre_parts_pay;
            form.address = "";
            form.rtd = state.choose_deliver_time;
            form.if_save = isSaveValue;
            form.if_check = isCheckValue;
            form.if_wait = isWaitValue;
            form.other = state.c_other_require;
            form.visualInspection = state.c_out_check;
            form.pics = state.c_pics.toString();
            form.customerSignPic = state.c_sign_pics.toString();
            var formData = new URLSearchParams();
            formData.append('customerName',form.customerName);
            formData.append('phone',form.phone);
            formData.append('carNum',form.carNum);
            formData.append('km',form.km);
            formData.append('carType',form.caType);
            formData.append('carOil',form.carOil);
            formData.append('maintenanceStage',form.maintenanceStage);
            formData.append('customerStatement',form.customerStatement);
            formData.append('serviceType',form.serviceType);
            formData.append('staffName',form.staffName);
            formData.append('hourCost',form.hourCost);
            formData.append('partCost',form.partCost);
            formData.append('address',form.address);
            formData.append('rtd',form.rtd);
            formData.append('ifSave',form.if_save);
            formData.append('ifCheck',form.if_check);
            formData.append('staffNo',form.staffNo);
            formData.append('ifWait',form.if_wait);
            formData.append('other',form.other);
            formData.append('visualInspection',form.visualInspection);

            formData.append('conectorName',form.conectorName);
            formData.append('customerIdNum',form.customerIdNum);
            formData.append('customerAddress',form.customerAddress);
            formData.append('vin',form.vin);
            formData.append('engineNo',form.engineNo);
            formData.append('carSeries',form.carSeries);
            formData.append('color',form.color);
            formData.append('customerSex',form.customerSex);
            formData.append('customerType',form.customerType);
            formData.append('customerIntroducer',form.customerIntroducer);


            if(state.c_sign_pics.length > 0){   //如果有客户签名照，证明已确认
                formData.append('status',2)
            }else{
                formData.append('status',1)
            }
            formData.append('pics',form.pics)
            formData.append('customerSignPic',form.customerSignPic);

            let res = await saveInsepctionBill(formData);
            console.log(res);
            if(res.code == "0"){
                alert('接车环检单创建成功');
                state.list_num = res.data;
                if(state.c_sign_pics.length > 0){   //如果有客户签名照，证明已确认
                    state.footerBtnMsg = state.footerBtnMsgs[3]
                }else{
                    state.footerBtnMsg = state.footerBtnMsgs[1]
                }
                
            }else{
                alert('未知错误')
            }
        }else if(state.footerBtnMsg == state.footerBtnMsgs[1]){
            let res = await pushNotificationToUserWX({phone:state.c_phone,page:'pages/confirmbills/confirminterrogation?listNum=' + state.list_num,car_number1:state.c_car_plate_number,time2:state.choose_deliver_time,thing3:'请点击确认您的接车单'});
            alert(res.data);
            if(res.code == "0"){
                state.footerBtnMsg = state.footerBtnMsgs[2]
            }
        }else if(state.footerBtnMsg == state.footerBtnMsgs[2]){
             let res = await updateInterrogationListStatus({listNum:state.list_num,status:2});
             if(res.code == "0"){
                 alert('确认成功');
                 back();
             }
        }
        
    }
    //
    const back = () => {
        router.back();
    };
    const previewPhotos = (url) => {
        let index = state.c_pics.indexOf(url);
        if (index > -1){
            ImagePreview(state.c_pics,index);
        }
    }
    const deletePhoto = (url) => {
        if(state.footerBtnMsg != state.footerBtnMsgs[0]){
            return;
        }
        let index = state.c_pics.indexOf(url);
        if (index > -1){
            state.c_pics.splice(index,1);
        }
    }

    const previewPhotos_s = (url) => {
        let index = state.c_sign_pics.indexOf(url);
        if (index > -1){
            ImagePreview(state.c_sign_pics,index);
        }
    }
    const deletePhoto_s = (url) => {
        if(state.footerBtnMsg != state.footerBtnMsgs[0]){
            return;
        }
        let index = state.c_sign_pics.indexOf(url);
        if (index > -1){
            state.c_sign_pics.splice(index,1);
        }
    }

    let openScan = async () => {
        codeReader
        .listVideoInputDevices()
        .then((videoInputDevices) => {
            console.log("videoInputDevices", videoInputDevices, "摄像头设备");

            // 默认获取第一个摄像头设备id
            let firstDeviceId = videoInputDevices[0].deviceId; // 根据id选择摄像头

            decodeFromInputVideoFunc(firstDeviceId);
        })
        .catch((err) => {
            Toast.fail(err);
        });
    };

    let decodeFromInputVideoFunc = (firstDeviceId) => {
        codeReader.decodeFromInputVideoDeviceContinuously(
            null, // firstDeviceId  为null 时默认选择面向环境的摄像头
            "video",
            (result, err) => {
                if (result) {
                    Toast.success(result + "扫描结果");
                }
                if (err && !err) {
                    Toast.fail(err + "扫描失败");
                }
            }
        );
    };

    const showDatetimePicker = () => {
        state.showDateTimePicker = true;
    }
    // 时间选择
    // 时间选择 弹框的确认按钮
    const datetimePicker_formatter = (type, val) => {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      if (type == 'hour'){
          return val + '时'
      }
      if (type == 'minute'){
          return val + '分'
      }
      return val;
    }
    // 取消时间弹出框按钮
    const datetimePicker_onTimeOut = () => {
        state.showDateTimePicker = false;
    }
    // 将时间格式转化为正常的
    const datetimePicker_onShift = () => {
        state.changeDate = this.currentDate // Tue Sep 08 2020 00:00:00 GMT+0800 (中国标准时间)
    }
    // 时间弹出框  确认按钮
    const datetimePicker_onTimeIn = (value) => {
        // console.log(value);
        state.choose_deliver_time = timeFormat(value);
        state.showDateTimePicker = false;
    }
    const timeFormat = (time) => { // 时间格式化 2019-09-08
        let year = time.getFullYear();
        let month = time.getMonth() + 1;
        let day = time.getDate();
        let hour = time.getHours();
        let minute = time.getMinutes();
        if(minute < 10){
            minute = '0' + minute.toString();
        }
        return year + '-' + month + '-' + day  + ' ' + hour + ':' + minute + ':00'
    }
</script>
<style lang="scss" scoped>
.box{
    background: #F1F5F8;
    min-height: 100vh;
    .navBar{
        background: #fff;
    }
    .container{
        background: #F1F5F8;
        overflow: hidden;
        .importCar{
            height: 3.4375rem /* 55/16 */;
            background: #FFFFFF;
            border-radius: .625rem /* 10/16 */;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding:0 .9375rem /* 15/16 */;
            button{
                background: transparent;
                border:none;
                font-size: 1rem /* 16/16 */;
                font-family: PingFang SC;
                font-weight: 500;
                color: #101D34;
                line-height: 1.125rem /* 18/16 */;
            }
        }
        .formContent{
            padding:.9375rem /* 15/16 */;
        }
        .baseMsg{
            position: relative;
            span:nth-of-type(1){
                position: absolute;
                top: .625rem /* 10/16 */;
                left: 3.4375rem /* 55/16 */;
            }
            span:nth-of-type(2){
                position: absolute;
                top: 3.75rem /* 60/16 */;
                left: 4.375rem /* 70/16 */;
            }
            span:nth-of-type(3){
                position: absolute;
                top: 6.875rem /* 60/16 */;
                left: 4.375rem /* 70/16 */;
            }
            span:nth-of-type(4){
                position: absolute;
                top: 9.875rem /* 60/16 */;
                left: 5.375rem /* 70/16 */;
            }
            span:nth-of-type(5){
                position: absolute;
                top: 13.075rem /* 60/16 */;
                left: 4.375rem /* 70/16 */;
            }
            span:nth-of-type(6){
                position: absolute;
                top: 16.275rem /* 60/16 */;
                left: 3.375rem /* 70/16 */;
            }
            span:nth-of-type(7){
                position: absolute;
                top: 19.275rem /* 60/16 */;
                left: 3.375rem /* 70/16 */;
            }
            span:nth-of-type(8){
                position: absolute;
                top: 22.475rem /* 60/16 */;
                left: 4.375rem /* 70/16 */;
            }
            span:nth-of-type(9){
                position: absolute;
                top: 25.475rem /* 60/16 */;
                left: 5.375rem /* 70/16 */;
            }
            span:nth-of-type(10){
                position: absolute;
                top: 28.675rem /* 60/16 */;
                left: 3.375rem /* 70/16 */;
            }
            span:nth-of-type(11){
                position: absolute;
                top: 31.675rem /* 60/16 */;
                left: 3.375rem /* 70/16 */;
            }
            span:nth-of-type(12){
                position: absolute;
                top: 34.875rem /* 60/16 */;
                left: 3.375rem /* 70/16 */;
            }

        }
        .search{
            font-size: 1rem /* 16/16 */;
            font-family: PingFang SC;
            font-weight: 400;
            color: #5E2F88;
            background: transparent;
            border:none;
        }
        h3 {
            text-align: left;
            text-indent: 0.4rem /* 30/75 */;
            margin: 16px 0;
        }
        .submitBtn{
            margin-top:2.5rem /* 40/16 */;
            button{
                width: 18.125rem /* 290/16 */;
                height: 2.75rem /* 44/16 */;
                background: linear-gradient(10deg, #5E2F88, #694AA5);
                border-radius: 1.375rem /* 22/16 */;
                border:none;
                margin:auto;
            }
        }
    }
}

footer {
    width: 100%;
    height: 5.625rem /* 90/16 */;
    background: #FFFFFF;
    box-shadow: 0px -10px 32px 8px rgba(0, 0, 0, 0.03);
    border-radius: .9375rem /* 15/16 */ .9375rem /* 15/16 */ 0px 0px;
    position: fixed;
    bottom: 0;
    text-align: center;
    button{
        width: 18.125rem /* 290/16 */;
        height: 2.75rem /* 44/16 */;
        margin-top:1.25rem /* 20/16 */;
        border:none;
        outline: none;
        border-radius: 1.375rem /* 22/16 */;
    }
    .bar{
        width: 8.375rem /* 134/16 */;
        height: .3125rem /* 5/16 */;
        background: #000000;
        border-radius: .15625rem /* 2.5/16 */;
        margin:13px auto 0;
    }
}
.van-calendar {
    height: 50%;
}
.van-cell-group{
    margin: 0!important;
}
</style>
<style scoped>
.container :deep() .van-field__label{
    font-size: 1rem /* 16/16 */;
    font-family: PingFang SC;
    font-weight: 500;
    color: #101D34;
}
.container :deep() .van-cell{
    min-height: 3.125rem /* 50/16 */;
    align-items: center;
    line-height:1.125rem /* 18/16 */;
}



</style>