export default {
    namespaced:true,
    state:{
        //有故障的零件列表
        fixList:[],
        //要提交的车辆信息
        carInfo:{
            name:'',//车主姓名
            detection_license_model:'',//车型
            detection_license_num:'',//车牌号
            open_phone:'', //电话
            detection_vin:'',//车架号
            detection_board:'' //上牌时间
        }
    },
    getters:{
        findFixById(state,id){
            return state.fixList.find(item=>item.id==id)
        },
        getCarInfo(state){
            return state.carInfo;
        }
    },
    mutations:{
        removeFixById(state,id){
            var index = state.fixList.findIndex(item=>item.id==id)
            state.fixList.splice(index,1);
        },
        clearFixList(state){
            state.fixList=[];
        },
        addFixList(state,item){
            state.fixList.push(item)
        },
        saveCarInfo(state,car){
            state.carInfo = car;
        }
    },
    actions:{}
}