<template>
    <div class="box">
        <van-nav-bar
            title="领料"
            class="navBar"
            left-arrow
            @click-left="back()"
            :border="false"
        ></van-nav-bar>
        <form action="/">
            <van-search
                v-model="searchValue"
                show-action
                placeholder="机滤"
                @search="onSearch"
            >
                <template #action>
                    <div @click="onSearch">搜索</div>
                </template>
            </van-search>
        </form>
        <div class="container">
            <van-cell-group inset v-for="(item,index) in 4" :key="index">
                <van-cell>曼牌机滤W712/90 高尔夫1.4GTI/明锐</van-cell>
                <van-cell class="cell-list">
                    <div class="surplus">库存: <span class="purple">27</span></div>
                    <div class="btn-wrap">
                        <div class="btn">
                            <van-icon name="minus" size=".5rem" />
                        </div>
                        <span class="count">1</span>
                        <div class="btn">
                            <van-icon name="plus" size=".5rem" />
                        </div>
                    </div>
                </van-cell>
            </van-cell-group>
        </div>
        <footer>
            <van-button color="#60348d" class="changeCode">确认选择</van-button>
        </footer>
    </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
const back = () => {
    router.back();
};
</script>
<style lang='scss' scoped>
.van-cell-group {
    margin: 0 !important;
    margin-bottom: 0.9375rem!important;
}
.box {
    background: #f1f5f8;
    height: 100vh;

    .navBar {
        background: #fff;
        border: none;
    }
    .container {
        padding: 0.9375rem;
        .purple{
            color: #60348d;
        }
        .btn-wrap {
            width: 6.375rem;
            height: 1.5625rem;
            border: 1px solid #8195a5;
            border-radius: 0.3125rem;
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            float: right;
            .btn {
                width: 1.5625rem;
                height: 1.5625rem;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                &:first-of-type {
                    border-right: 1px solid #8195a5;
                }
                &:last-of-type {
                    border-left: 1px solid #8195a5;
                }
            }
            .count {
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    footer {
        position: fixed;
        bottom: 0;
        text-align: center;
        width: 100%;
        color: white;
        height: 90px;
        background: #ffffff;
        box-sizing: border-box;
        box-shadow: 0px -10px 32px 8px rgba(0, 0, 0, 0.03);
        border-radius: 0.9375rem 0.9375rem 0px 0px;
        padding-top: 0.625rem;
        .van-button {
            width: 18.125rem;
            height: 2.75rem;
            border-radius: 1.375rem;
            color: #fff;
            font-size: 1.125rem;
        }
    }
}
</style>
<style scoped>
form :deep() .van-search__content {
    border-radius: 1rem;
}
.box :deep() .van-cell__title{
    text-align: left;  
}
.cell-list :deep()  .van-cell__value{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>