import request from 'utils/request'
import {urlWithParams} from 'utils/utils.js'

/**
 *  创建接车环检单
 * @data {*} data 
 * @returns 
 */
export const saveInsepctionBill = async (data) => {
    return await request({
        url:'/auth/interrogation/saveInterrogationList',
        method:'post',
        data
    })
}

/**
 *  推送环检单消息给客户微信
 * @data {*} data 
 * @returns 
 */
 export const pushNotificationToUserWX = async (data) => {
    return await request({
        url:urlWithParams('/auth/messageSend/sendWebappMessage',data),
        method:'post',
        data
    })
}

/**
 *  确认环检单
 * @data {*} data 
 * @returns 
 */
 export const updateInterrogationListStatus = async (data) => {
    return await request({
        url:urlWithParams('/auth/interrogation/updateInterrogationListStatus',data),
        method:'post',
        data
    })
}