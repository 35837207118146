import { createStore } from "vuex";
import user from './user';
import testing from './testing'
import goods from './goods'
export default createStore({
  state:{
    loading:false,
    requestNum:0
  },
  mutations:{
    startLoading(state) {
      if (state.requestNum == 0) {
        state.loading = true;
      }
      state.requestNum++;
    },
    // 结束loading
    endLoading(state) {
      state.requestNum--;
      if (state.requestNum <= 0) {
        setTimeout(() => {
          state.requestNum = 0;
          state.loading = false;
        }, 100);
      }
    }
  },
  modules:{
    user,
    testing,
    goods
  }
})