import request from 'utils/request'

/**
 * 员工登录
 * @param {*} params 
 * @returns 
 */
export const login = async (params) => {
  return await request({
    url:'/auth/carFittings/login',
    method:'get',
    params
  })
}