<template>
    <van-nav-bar title="维修工列表" left-arrow @click-left="back()"></van-nav-bar>
    <view style="margin-top:20px">
        <van-button v-for="item in state.list" :key="item" style="width:100%;margin-top:10px;" @click="chooseOne(item)">{{item.name}}</van-button>
    </view>
</template>

<script setup>
    import {computed, onMounted, reactive, ref} from 'vue'
    import { useRoute, useRouter } from 'vue-router';
    import { employeesList } from 'api/employee.js'
    import { updateRepairOrder } from 'api/repair.js'
    import {getNowDateFormat} from '../../utils/utils.js'
    const router = useRouter()
    const route = useRoute()

    //本组件的状态
    const state = reactive({
        list:[],
        targetItem:{},
        loading: false,
        finished: false,
    });
    

    const back = () => {
        router.back();
    }

    const query = async ()=>{
        let res = await employeesList({remarkFou:1});
        if(res.code == "0"){
            let resultArray = [];
            for(let i = 0; i < res.data.allStaff.length; i ++){
                let item = res.data.allStaff[i];
                if(item.remarkOne.indexOf('维修工') != -1){
                    resultArray.push(item);
                }
            }
            state.list = resultArray;
        }
    }


    onMounted(()=>{
        // console.log(route.query);
        state.targetItem = JSON.parse(route.query.item);
        query();
    })

    const chooseOne = async (item) => {
        state.targetItem.staffNo = item.staffNo;
        var res = await updateRepairOrder({id:state.targetItem.id,staffNo:item.staffNo,status:'待接单',masterToStaffTime:getNowDateFormat()})
        if(res.code == "0"){
            alert('指派成功');
            back();
        }
    }
    
</script>

<style scoped>

</style>