<template>
  <!-- <div v-if="$store.state.loading" class="loading"> -->
    <van-overlay :show="$store.state.loading">
      <van-loading type="spinner" vertical>加载中...</van-loading>
    </van-overlay>
  <!-- </div> -->
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss" scoped>
.van-loading{
  position:absolute;
  width:5rem;
  height:5rem;
  top:50%;
  left:50%;
  margin-left: -2.5rem;
  margin-top: -2.5rem;
}
</style>
