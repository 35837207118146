import axios from "axios";
import store from 'store'
const service = axios.create({
  baseURL:import.meta.env.VITE_BASE_API,
  timeout: 5000 // request timeout
});
// 发起请求之前的拦截器
service.interceptors.request.use(
  config => {
    // 如果有token 就携带tokon
    const token = window.localStorage.getItem("accessToken");
    if (token) {
      config.headers.common.Authorization = token;
    }

    //全局loading
    store.commit('startLoading')

    return config;
  },
  error => Promise.reject(error)
);
// service.defaults.headers = {'Content-Type': 'application/json'}
// service.defaults.headers = {"Content-Type": "application/x-www-form-urlencoded"}
// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data;
    store.commit('endLoading')
    if (response.status !== 200) {
      return Promise.reject(new Error(res.msg || "Error"));
    } else {
      return res;
    }
  },
  error => {
    store.commit('endLoading')
    return Promise.reject(error);
  }
);
export default service;
