import { createRouter,createWebHistory} from "vue-router";
import store from 'store'
import Paycode from 'views/goods/paycode.vue'
import Employee from 'views/repair/employee.vue'
import Take from 'views/repair/take.vue'
import TakeLiao from 'views/repair/takeliao.vue'
import Xiangmu from 'views/repair/xiangmu.vue'
import Inspection from 'views/insepction/index.vue'
import Subscribe from 'views/Subscribe/subscribe.vue'
const routes = [
  { path: "/", redirect: "/home" },
  {
    path: "/home",
    name: "home",
    meta:{title:'清研精准'},
    component: ()=>import('views/home.vue')
  },
  {
    path: "/login",
    name: "login",
    meta:{title:'员工登录'},
    component: ()=>import('views/login/index.vue')
  },
  {
    path: "/car",
    name: "car",
    meta:{title:'店内车辆'},
    component: ()=>import('views/car/index.vue')
  },
  {
    path: "/customer",
    name: "customer",
    meta:{title:'客户查询'},
    component: ()=>import('views/customer/index.vue')
  },
  {
    path: "/goods",
    name: "goods",
    meta:{title:'零售快捷收款'},
    component: ()=>import('views/goods/index.vue')
  },
  {
    path: "/goods/combo",
    name: "combo",
    meta:{title:'套餐卡快捷收款'},
    component: ()=>import('views/goods/combo.vue')
  },
  {
    path: "/goods/confirm/:orderType",
    name: "confirm",
    meta:{title:'订单确认'},
    component: ()=>import('views/goods/confirm.vue')
  },
  {
    path: "/goods/paycode/:orderSn",
    name: "paycode",
    meta:{title:'二维码收款'},
    //生产环境下：动态组件 + 异步代码结构 导致无法跳转
    component: Paycode
  },
  {
    path: "/order/writeOff",
    name: "writeOff",
    meta:{title:'订单-线上核销'},
    component: ()=>import('views/order/writeOff.vue')
  },
  {
    path: "/order/reserve",
    name: "reserve",
    meta:{title:'订单-预约订单'},
    component: ()=>import('views/order/reserve.vue')
  },
  {
    path: "/parts",
    name: "parts",
    meta:{title:'配件入库单'},
    component: ()=>import('views/parts/index.vue')
  },
  {
    path: "/register",
    name: "register",
    meta:{title:'会员注册'},
    component: ()=>import('views/register/index.vue')
  },
  {
    path: "/register/qrcode/:result",
    name: "qrcode",
    meta:{title:'登录小程序'},
    component: ()=>import('views/register/qrcode.vue')
  },
  {
    path: "/sales",
    name: "sales",
    meta:{title:'我的业务单'},
    component: ()=>import('views/sales/index.vue')
  },
  {
    path: "/sales/detail/:id/:sn",
    name: "detail",
    meta:{title:'销售单详情'},
    component: ()=>import('views/sales/detail.vue')
  },
  {
    path: "/testing",
    name: "testing",
    meta:{title:'车辆检测-基础信息'},
    component: ()=>import('views/testing/index.vue')
  },
  {
    path: "/testing/report",
    name: "report",
    meta:{title:'车辆检测-检测报告填写'},
    component: ()=>import('views/testing/report.vue')
  },
  {
    path: "/testing/error/:id",
    name: "error",
    meta:{title:'车辆检测-异常记录页'},
    component: ()=>import('views/testing/error.vue')
  },
  {
    path:"/:catchAll(.*)", //vue3的写法，vue2直接写 * 
    name:'notFound',
    meta:{title:'页面走丢了'},
    component:()=>import('views/404.vue')
  },
  {
    path: "/repair/list",
    name: "list",
    meta:{title:'施工列表'},
    component: ()=>import('views/repair/list.vue')
  },
  {
    path: "/repair/take",
    name: "take",
    meta:{title:'领料'},
    // component: ()=>import('views/repair/take.vue')
    component : Take
  },
  {
    path: "/repair/takeliao",
    name: "takeliao",
    meta:{title:'领料'},
    // component: ()=>import('views/repair/takeliao.vue')
    component: TakeLiao
  },
  {
    path: "/repair/employee",
    name: "employee",
    meta:{title:'指派施工人'},
    // component: ()=>import('views/repair/employee.vue')
    //生产环境下：动态组件 + 异步代码结构 导致无法跳转
    component: Employee
  },
  {
    path: "/repair/xiangmu",
    name: "xiangmu",
    meta:{title:'维修单项目'},
    // component: ()=>import('views/repair/xiangmu.vue')
    component : Xiangmu
  },
  {
    path: "/insepction",
    name :"inspection",
    meta:{title:"接车环检"},
    component : Inspection
  },
  {
    path: "/Subscribe",
    name :"subscribe",
    meta:{title:"订阅消息"},
    component : Subscribe
  }
]
const router = createRouter({
  history: createWebHistory(), //createWebHistory  or createWebHashHistory
  routes: routes
})

router.beforeEach((to,from,next)=>{
  document.title = to.meta.title
  // console.log('navigation-guards');
  // console.log('from:',from.path);
  // console.log('to:',to.path);
  let userInfo = store.getters['user/users']
  if(!userInfo && to.path!='/login'){
    next('/login')
  }else{
    next();
  }
})

export default router;