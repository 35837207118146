
export const urlWithParams = (url, obj) => {
	let result = '';
	let item;
	if (url.includes("?")) {
		for (item in obj) {
			if (obj[item] && String(obj[item])) {
				result += `&${item}=${obj[item]}`;
			}
		}
	}else{
		result += "?"
		for (item in obj) {
			if (obj[item] && String(obj[item])) {
				result += `&${item}=${obj[item]}`;
			}
		}
	}
	return url + result;
}

export const getDayName = (d) => {
	var td=new Date();
	td=new Date(td.getFullYear(),td.getMonth(),td.getDate());
	var od=new Date(d);
	od=new Date(od.getFullYear(),od.getMonth(),od.getDate());
	var xc=(od-td)/1000/60/60/24;
	if(xc<-2){
	   return -xc+"天前";
	}else if(xc<-1){
	   return "前天";
	}else if(xc<0){
	   return "昨天";
	}else if(xc==0){
	   return "今天";
	}else if(xc<2){
	   return "明天";
	}else if(xc<3){
	   return "后天";
	}else{
	   return xc+"天后";
	}
 }

 export const  getNowDateFormat =  () => { // author: meizz
	var now = new Date();

        var year = now.getFullYear();       //年
        var month = now.getMonth() + 1;     //月
        var day = now.getDate();            //日

        var hh = now.getHours();            //时
        var mm = now.getMinutes();          //分
        var ss = now.getSeconds();          //分

        var clock = year + "-";

        if(month < 10)
            clock += "0";

        clock += month + "-";

        if(day < 10)
            clock += "0";

        clock += day + " ";

        if(hh < 10)
            clock += "0";

        clock += hh + ":";
        if (mm < 10)
            clock += '0';
        clock += mm + ":";

        if (ss < 10)
            clock += '0';
        clock += ss;
        return clock;
  }