//按需引入Vant
import {
    Tabbar,
    TabbarItem,
    Calendar,
    Image as VanImage,
    NavBar,
    Cell,
    CellGroup,
    Dialog,
    Col,
    Row,
    Grid,
    GridItem,
    Icon,
    Card,
    Tab,
    Tabs,
    Stepper,
    Button,
    Uploader,
    Loading,
    Form,
    Field,
    Empty,
    RadioGroup,
    Radio,
    Search,
    List,
    Tag,
    Popup ,
    Overlay,
    Checkbox, CheckboxGroup,Collapse, CollapseItem 
  } from 'vant'
export default function(app) {
    app.use(Button)
    .use(Tabbar)
    .use(Empty)
    .use(NavBar)
    .use(TabbarItem)
    .use(VanImage)
    .use(Form)
    .use(Field)
    .use(CellGroup)
    .use(Cell)
    .use(Grid)
    .use(GridItem)
    .use(Calendar)
    .use(Icon)
    .use(Uploader)
    .use(RadioGroup)
    .use(Radio)
    .use(Row)
    .use(Col)
    .use(Stepper)
    .use(Search)
    .use(Card)
    .use(Dialog)
    .use(Tab)
    .use(Tabs)
    .use(List)
    .use(Loading)
    .use(Overlay)
    .use(Tag).use(Popup).use(Checkbox).use(CheckboxGroup).use(Collapse ).use(CollapseItem)
}