import {login} from 'api/user'
import { Toast } from 'vant'
import  router from 'routes'
const user = {
  namespaced:true,
  state:{
    userInfo:null
  },
  getters:{
    users(state){
      if(!state.userInfo && localStorage.getItem('userInfo')){
        state.userInfo = JSON.parse(localStorage.getItem('userInfo')) || null;
      }
      return state.userInfo
    }
  },
  mutations:{
    exit(state){
      state.userInfo = null;
      localStorage.removeItem('userInfo');
      console.log('退出登录')
      router.replace('/login')
    },
    login(state,form){
      console.log('登录成功');
      localStorage.setItem('userInfo',JSON.stringify(form));
      state.userInfo = form;
    }
  },
  actions:{
    async login({commit},form){
      let res = await login(form)
      console.log('登录结果：',res.data)
      if(!res.data){
        Toast.fail('编号或密码错误')
      }else{
        Toast.success('登录成功')
        // if(res.data){
          commit('login',res.data);
        // }
        router.replace('/home')
      }
    }
  }
}
export default user;