import request from 'utils/request'

/**
 * 零售收款商品列表
 * @param {*} params 
 * @returns 
 */
export const goodsList=async (params)=>{
    return await request({
        url:'/auth/shop/product/appProductMessageList',
        method:'get',
        params
    })
}

/**
 *  套餐卡列表搜索
 * @param {*} params 
 * @returns 
 */
export const comboList = async (params) => {
    return await request({
        // url:'/auth/shop/product/appProductPiabjk',
        url:'/auth/shop/product/appProductMessageList',
        method:'get',
        params
    })
}

/**
 *  套餐卡项目明细
 * @param {*} params 
 * @returns 
 */
export const comboDetail = async (params) => {
    return await request({
        url:'/auth/shop/product/carProductPiabjkDetail',
        method:'get',
        params
    })
}


/**
 *  生成收款码(创建订单)
 * @param {*} data 
 * @returns 
 */
export const saveOrder = async (data) => {
    return await request({
        url:'/auth/shop/order/saveCarOrder',
        method:'post',
        data
    })
}

/**
 *  生成付款二维码地址
 * @param {*} orderId 
 * @returns 
 */
export const pay  = async (orderId) => {
    return await request({
        url:'/auth/shop/payment/payMoney?orderId='+orderId,
        method:'POST'
    })
}

/**
 *  订单确认收款
 * @param {*} orderSn 
 * @returns 
 */
export const confirmPay = (params)=>{
    return request({
        url:'/auth/shop/payment/callback2?orderSn='+params.orderSn + '&userPhone=' + params.userPhone
    })
}

/**
 *  创建结算单
 * @param {*} data 
 * @returns 
 */
 export const saveSettlement = async (data) => {
    return await request({
        url:'/auth/settlement/saveSettlement',
        method:'post',
        data
    })
}