<template>
    <van-nav-bar title="维修单配件" left-arrow @click-left="back()"></van-nav-bar>
    <view style="margin-top:25px">
        <view class="existxm">
            <span>维修单号：</span>
            <span style="margin-top:2.5px">{{state.item.id}}</span>
        </view>
        <view class="existxm" style="height:30px">
            <!-- <span style="font-size:20px;font-weight:600">维修单配件</span> -->
        </view>
        <view v-for="item in state.ex_fittingsList" :key="item.label" class="existxm" style="margin-top:10px">
            <span class="xmname">[{{item.label}}]</span>
            <span class="xmcount"> x{{item.remark}} </span>
        </view>
        <!-- <view class="existxm">
            <span style="font-size:20px;font-weight:600">添加配件</span>
        </view>
        <view v-for="item in state.pre_add_list" :key="item.name" class="existxm" style="margin-top:10px">
            <span class="xmname">{{item.name}}</span>
            <van-button style="color:red;height:1.5rem;margin-left:1rem" @click="clickReduce(item)">删除</van-button>
        </view>
        <van-search
            v-model="state.searchValue"
            show-action
            placeholder="请输入搜索关键词"
            @search="onSearch"
            style="margin-top:5px"
        >
            <template #action>
                <div @click="onSearch" style="margin-left:0px">搜索</div>
            </template>
        </van-search>
        <view v-for="item in state.fittingsList" :key="item.name" class="existxm" style="margin-top:10px">
            <span class="xmname" style="margin-left:0px;margin-top:10px">{{item.name}}（￥{{item.price}}）</span>
            <van-button style="margin-left:20px" @click="clickPlus(item)">+</van-button>
        </view>
        <footer>
            <van-button color="#60348d" class="changeCode" @click="clickSure" :disabled="state.pre_add_list.length<=0">确认添加</van-button>
        </footer>
        <van-dialog v-model:show="state.showConfirmDialog" title="确认增加配件？" show-cancel-button messageAlign="left" confirmButtonText="确认" @confirm="confirmCancel()">   
        </van-dialog> -->
    </view>
</template>

<script setup>
    import {computed, onMounted, reactive, ref} from 'vue'
    import { useRoute, useRouter } from 'vue-router';
    import { getFittingsList} from 'api/repair.js'
    import {useStore} from 'vuex'

    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    //本组件的状态
    const state = reactive({
        item : {},
        fittingsList:[],
        ex_fittingsList:[], //已添加的零配件列表
        ex_hourPayList:[],  //已添加的工时费列表
        loading: false,
        finished: false,
        pre_add_list : [],  //待添加的项目
        origin_fittingsList: [],
        searchValue : "",
        showConfirmDialog:false
    });
    

    const back = () => {
        router.back();
    }

    const query = async ()=>{
        var userInfo = store.getters['user/users'];
        let res = await getFittingsList()
        if(res.code == "0"){
            state.origin_fittingsList = res.data;
            state.fittingsList = res.data;
        }
    }

    
    onMounted(()=>{
        let targetItem = JSON.parse(route.query.item);
        state.item = targetItem;
        if(targetItem.fittingsList != undefined){
            state.ex_fittingsList = JSON.parse(targetItem.fittingsList);
        }
        if(targetItem.hourPayList != undefined){
            state.ex_hourPayList = JSON.parse(targetItem.hourPayList);
        }
        query();
    })

    const clickPlus = (item) => {
        state.pre_add_list.push(item);
    }

    const clickReduce = (item) => {
        var index = state.pre_add_list.indexOf(item);
        if(index > -1){
            state.pre_add_list.splice(index,1)
        }
    }

    const onSearch = () => {
        if(state.searchValue.length == 0){
            state.fittingsList = state.origin_fittingsList;
        }else{
            var resultArray = [];
            for(var i = 0; i < state.origin_fittingsList.length; i ++){
                var item = state.origin_fittingsList[i];
                if(item.name.indexOf(state.searchValue) > -1){
                    if(item.label.length > 0){
                        item.name = item.label;
                    }
                    resultArray.push(item);
                }
            }
            state.fittingsList = resultArray;
        }
    }

    const clickSure = () => {
        state.showConfirmDialog = true;

    }

    const confirmCancel = async () => {
        //网络请求添加项目
        const paramArray = state.ex_fittingsList.concat(state.pre_add_list);

        var p = {
            id:state.item.id,
            fittingsList:JSON.stringify(paramArray)
        }
        let res = await updateRepairOrdersv2(p)
        if(res.code == "0"){
            alert('添加领料成功!');
            back();
        }
    }

    
</script>

<style scoped>
.existxm{
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    margin-left: 25px;
}
.xmname{
    font-size:.8rem;
}
.xmcount{
    font-size: .8rem;
    margin-left:20px;
    margin-top:.22rem;
}
.changeCode{
    width: 18.125rem;
    height: 2.75rem;
    border-radius: 1.375rem;
    color: #fff;
    font-size: 1.125rem;
    margin-top:1rem;
    margin-bottom: 1rem;
}
</style>