import request from 'utils/request'

/**
 *  员工列表
 * @param {*} data 
 * @returns 
 */
export const employeesList = async (data) => {
    return await request({
        url:'/auth/carFittings/getStaffList',
        method:'get',
        params:data
    })
}