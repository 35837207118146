import { createApp } from 'vue'
import App from './App.vue'
// import 'lib-flexible/flexible'
import router from './routes'
import store from './store'
import vant3 from './plugins/vant3'

//import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import './index.css'
import {DatetimePicker} from 'vant'
import {Picker} from 'vant'
import { ImagePreview } from 'vant'
createApp(App).use(router).use(store).use(vant3).use(DatetimePicker).use(Picker).use(ImagePreview).mount('#app')

