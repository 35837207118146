import request from 'utils/request'

/**
 *  拉取维修单列表
 * @param {*} data 
 * @returns 
 */
export const repairList = async (data) => {
    return await request({
        url:'/auth/maintenance/getAllMaintenanceOrder',
        method:'get',
        params:data
    })
}

/**
 *  新增或者修改某个维修单属性：注，这里如果id不为空则是修改，id为空则是新增
 * @param {*} data 
 * @returns 
 */
 export const updateRepairOrder = async (data) => {
    return await request({
        url:'/auth/maintenance/addOrUpdateMaintenanceOrder',
        method:'get',
        params:data
    })
}

/**
 *  删除某个维修单，参数传个id即可
 * @param {*} data 
 * @returns 
 */
 export const deleteRepairOrder = async (data) => {
    return await request({
        url:'/auth/maintenance/delMaintenanceOrder',
        method:'get',
        params:data
    })
}

/**
 *  获取工时费项目列表
 * @param {*} data 
 * @returns 
 */
 export const getHourPayList = async (data) => {
    return await request({
        url:'/auth/carFittings/getHourPay',
        method:'get',
        params:data
    })
}

/**
 *  获取所有配件列表
 * @param {*} data 
 * @returns 
 */
 export const getFittingsList = async (data) => {
    return await request({
        url:'/auth/carFittings/getAllCarFittings?page=1&limit=999999',
        method:'get',
        params:data
    })
}

/**
 *  新增或者修改某个维修单属性：注，这里如果id不为空则是修改，id为空则是新增。 v2post版
 * @param {*} data 
 * @returns 
 */
 export const updateRepairOrdersv2 = async (data) => {
    return await request({
        url:'/auth/maintenance/addOrUpdateMaintenanceOrders',
        method:'post',
        // params:data
        data
    })
}