<template>
    <div class="box">
        <van-nav-bar
            title="二维码收款"
            class="navBar"
            left-arrow
            @click-left="back()"
            :border="false"
        ></van-nav-bar>
        <div class="container">
            <van-cell-group inset>
                <van-cell>
                    <div class="tip">
                        <van-image
                            width="1.5625rem"
                            height="1.5625rem"
                            src="/img/icon_ewm.png"
                        />
                        <span class="tip-text">下方为付款码,请使用微信扫码付款</span>
                    </div>
                    <div class="img-wrap">
                        <!-- <van-image
                            width="18.4375rem"
                            height="18.4375rem"
                            fit="fill"
                            :src="img"
                        /> -->
                        <div class="qrcodeMain">
                            <div class="codeImg" id ="query">
                                <canvas id="canvas" width="18.4375rem" height="18.4375rem"></canvas>
                            </div>
                        </div>
                        <div style="margin-bottom:5rem;">
                            <label style="vertical-align:middle;">{{route.params.orderSn}}</label>&nbsp;
                            <van-button size="mini" style="top:-2px;"  class="cobyOrderSn" type="warning" data-clipboard-action="copy" :data-clipboard-text="route.params.orderSn" @click="copyOrderSn()">复 制</van-button>
                        </div>
                        <van-button round block @click="ok()" color="#60348d" :disabled="state.userPhone.length != 11">确认支付完成</van-button>
                    </div>
                </van-cell>
            </van-cell-group>
        </div>
    </div>
    <van-dialog v-model:show="state.showConfirmToPaySuccess" title="确认支付完成?" show-cancel-button messageAlign="left" confirmButtonText="确认" @confirm="okok()">   
    </van-dialog>
</template>

<script setup>
import { useRouter,useRoute } from "vue-router";
import img from "assets/qrcode.jpg";
import ewm from "assets/icon_ewm.png";
import { onMounted, reactive } from "@vue/runtime-core";
import QRCode from 'qrcode'     //引入生成二维码组件
import {confirmPay,saveSettlement} from 'api/goods.js'
import { Toast } from "vant";
import { useStore } from "vuex";
import clipboard from 'clipboard'; //复制到粘贴板

const router = useRouter();
const route = useRoute();
const store = useStore();

onMounted(()=>{
    var code_url= sessionStorage.getItem("code_url");
    var cur_customer_tel = sessionStorage.getItem("cur_customer_tel");
    console.log(code_url)
    var canvas = document.getElementById('canvas')
    QRCode.toCanvas(canvas, code_url, function (error) {
        if (error) console.error(error)
        console.log('已生成二维码!');
    })
})

const state = reactive({
    showConfirmToPaySuccess : false,
    userPhone : sessionStorage.getItem("cur_customer_tel"),
    totalPrice : sessionStorage.getItem("cur_price"),
})

//订单确认
const ok = ()=>{
    state.showConfirmToPaySuccess = true;
}

const okok = async () =>{
    let userInfo = store.getters['user/users'];
    let res = await confirmPay({orderSn:route.params.orderSn,userPhone:state.userPhone})
    if(res.code==0){
        console.log(route.params);
        var formData = new URLSearchParams();
        formData.append('maintenanceId',"");
        formData.append('orderSn',route.params.orderSn);
        formData.append('amountPayable',state.totalPrice);
        formData.append('amountCashPart',state.totalPrice);
        formData.append('amountWalletPart',0.0);
        formData.append('type','2');
        formData.append('status','1');
        formData.append('customerPhone',state.userPhone);
        formData.append('customerCarNumber','');
        formData.append('amountPackageOrderSnPart','');
        formData.append('amountPackageProjectPart','');
        if(userInfo.remarkOne.indexOf('维修') != -1){
            formData.append('staffNo',userInfo.staffNo);
            formData.append('staffName',userInfo.name);
        }else if(userInfo.remarkOne.indexOf('SA') != -1){
            formData.append('receiverNo',userInfo.staffNo);
            formData.append('receiverName',userInfo.name);
        }
        // 调用生成结算单
        let res2 = await saveSettlement(formData)
        if(res2.code == 0){
            Toast.success("订单确认成功")
            router.push('/home')
        }
    }
    
}

//复制订单号到粘贴板
const copyOrderSn = ()=>{
    let board = new clipboard(".cobyOrderSn");
     board.on('success', function () {
       Toast.success("订单号复制成功")
     });
     board.on('error', function () {
       Toast.fail("订单号复制失败")
     });
}

const back = () => {
    router.back();
};
</script>
<style lang='scss' scoped>
.van-cell-group {
    margin: 0 !important;
}
.box {
    background: #f1f5f8;
    height: 100vh;

    .navBar {
        background: #fff;
        border: none;
    }
    .container {
        padding: 0.9375rem;
        .img-wrap,.tip {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .img-wrap{
            flex-direction: column;
        }
        .tip {
            padding-bottom: 1.9375rem;
            padding-top: 0.625rem;
            .tip-text{
                margin-left: .9375rem;
            }
        }
        .img-wrap{
            padding-bottom: 1.25rem;
        }
    }
    footer {
        position: fixed;
        bottom: 0;
        text-align: center;
        width: 100%;
        color: white;
        height: 90px;
        background: #ffffff;
        box-shadow: 0px -10px 32px 8px rgba(0, 0, 0, 0.03);
        border-radius: 0.9375rem 0.9375rem 0px 0px;
        padding-top: 0.625rem;
    }
}
</style>
<style scoped>
.box :deep() .van-ellipsis {
    font-family: PingFang-SC-Bold;
    font-weight: 700;
}
.box :deep() .van-cell {
    padding: 0.9375rem;
}
</style>